import {
  GET_PROJECTS,
  GET_PROJECTDETAILS,
  RESET_PROJECT_DETAILS,
} from "../actions/actionTypes";

// Initial state with default values
const initialState = {
  projects: [],
  projectDetails: {},
};

// Reducer function
const projectsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PROJECTS:
      return {
        ...state,
        projects: action.payload, // Update the 'projects' array with the payload
      };
    case GET_PROJECTDETAILS:
      return {
        ...state,
        projectDetails: action.payload, // Update the 'projectDetails' object with the payload
      };
    case RESET_PROJECT_DETAILS:
      return {
        ...state,
        projectDetails: {}, // Reset projectDetails part of the state
      };
    default:
      return state; // Return the current state if no action matches
  }
};

export default projectsReducer;
