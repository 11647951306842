import {
  GET_PROJECTS,
  GET_PROJECTDETAILS,
  RESET_PROJECT_DETAILS,
} from "./actionTypes";
import { instance } from "./instance";

export const getProjects = () => {
  return async (dispatch) => {
    try {
      const response = await instance.get("projects");
      dispatch({
        type: GET_PROJECTS,
        payload: response.data,
      });
    } catch (err) {
      console.log(err);
    }
  };
};

export const getProjectDetails = (projectID) => {
  return async (dispatch) => {
    try {
      const response = await instance.get(`projects/media/${projectID}`);
      dispatch({
        type: GET_PROJECTDETAILS,
        payload: response.data,
      });
    } catch (err) {
      console.log(err);
    }
  };
};

export const resetProjectDetails = () => ({
  type: RESET_PROJECT_DETAILS,
});
