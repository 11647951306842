import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  getProjectDetails,
  resetProjectDetails,
} from '../../redux/actions/project';
import {
  Box,
  IconButton,
  Modal,
  Backdrop,
  Fade,
  CircularProgress,
  styled,
  Skeleton,
  Typography,
} from '@mui/material';
import { Close, PlayCircleFilled } from '@mui/icons-material';
import logo from '../../assets/images/logo.png';

const ModalContent = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '100vw',
  height: '100vh',
  backgroundColor: '#fff',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  overflow: 'hidden', // Prevent scrollbars
}));

const Logo = styled('img')({
  position: 'absolute',
  top: '10px',
  left: '10px',
  width: '100px',
  height: 'auto',
});

const ButtonContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  marginTop: '10px',
});

const PlayButton = styled(PlayCircleFilled)({
  fontSize: 64,
  color: 'rgba(255, 255, 255, 0.9)',
  cursor: 'pointer',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
});

const ProjectDetails = () => {
  const { projectID } = useParams();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const projectDetails = useSelector(
    (state) => state.projectState.projectDetails
  );
  const [open, setOpen] = useState(false);
  const [currentMediaIndex, setCurrentMediaIndex] = useState(0);
  const [loadedMedia, setLoadedMedia] = useState({});
  const [loadedMediaThumb, setLoadedMediaThumb] = useState({});

  useEffect(() => {
    const fetchDetails = async () => {
      setIsLoading(true);
      await dispatch(getProjectDetails(parseInt(projectID)));
      setIsLoading(false);
    };

    fetchDetails();

    return () => {
      dispatch(resetProjectDetails());
    };
  }, [projectID, dispatch]);

  useEffect(() => {
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    if (isSafari) {
      document.body.classList.add('safari');
    }
  }, []);

  if (isLoading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '500px',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!projectDetails || Object.keys(projectDetails).length === 0) {
    return (
      <div className="centered-content">
        <span>Project not found</span>
      </div>
    );
  }

  const mediaItems = [
    ...(projectDetails.images || []),
    ...(projectDetails.videos || []),
  ].sort((a, b) => a.order - b.order);

  const handleOpenModal = (index) => {
    setCurrentMediaIndex(index);
    setOpen(true);
  };

  const handleMediaLoad = (mediaId) => {
    setLoadedMedia((prev) => ({ ...prev, [mediaId]: true }));
  };

  const handleMediaLoadThumb = (mediaId) => {
    setLoadedMediaThumb((prev) => ({ ...prev, [mediaId]: true }));
  };

  const handleCloseModal = () => {
    setOpen(false);
    setCurrentMediaIndex(0);
  };

  const handlePrevMedia = () => {
    setCurrentMediaIndex((prevIndex) =>
      prevIndex <= 0 ? mediaItems.length - 1 : prevIndex - 1
    );
  };

  const handleNextMedia = () => {
    setCurrentMediaIndex((prevIndex) =>
      prevIndex >= mediaItems.length - 1 ? 0 : prevIndex + 1
    );
  };

  return (
    <div className="project-details-container">
      <Typography
        variant="h5"
        sx={{ fontFamily: 'DM Serif Display, sans-serif, Tajawal', py: 6 }}
      >
        {projectDetails.project.title}
      </Typography>
      {mediaItems.length > 0 && (
        <div className="image-list">
          {mediaItems.map((item, index) => (
            <div className="image-item" key={item.id}>
              {item.image_url ? (
                <>
                  <img
                    className={item.size ? item.size : ''}
                    src={item.low_res_image_url}
                    alt={item.title}
                    onLoad={() => handleMediaLoadThumb(item.id)}
                    loading="eager"
                    // Move the onClick handler here
                    onClick={() => handleOpenModal(index)}
                  />
                  {!loadedMediaThumb[item.id] && (
                    <Skeleton variant="rectangular" width={300} height={350} />
                  )}
                </>
              ) : (
                <>
                  <PlayButton onClick={() => handleOpenModal(index)} />
                  <img
                    className={item.size ? item.size : ''}
                    src={item.thumbnail_url}
                    alt="Video thumbnail"
                    onLoad={() => handleMediaLoadThumb(item.id)}
                    // Move the onClick handler here
                    onClick={() => handleOpenModal(index)}
                  />
                  {!loadedMediaThumb[item.id] && (
                    <Skeleton variant="rectangular" width={300} height={350} />
                  )}
                </>
              )}
            </div>
          ))}
        </div>
      )}
      {open && (
        <Modal
          open={open}
          onClose={handleCloseModal}
          BackdropComponent={Backdrop}
          BackdropProps={{ timeout: 500 }}
        >
          <Fade in={open}>
            <ModalContent>
              <Logo src={logo} alt="Logo" />
              <IconButton
                sx={{
                  position: 'absolute',
                  top: 2,
                  right: 2,
                  color: '#21252b',
                }}
                onClick={handleCloseModal}
              >
                <Close />
              </IconButton>
              {mediaItems[currentMediaIndex].image_url ? (
                <>
                  <img
                    src={mediaItems[currentMediaIndex].low_res_image_url}
                    alt={mediaItems[currentMediaIndex].title}
                    style={{
                      transition: 'opacity 0.5s ease-in-out',
                      opacity: loadedMedia[mediaItems[currentMediaIndex].id]
                        ? '0'
                        : '1',
                      display: loadedMedia[mediaItems[currentMediaIndex].id]
                        ? 'none'
                        : 'block',
                      maxWidth: '70%',
                      maxHeight: '70%',
                    }}
                  />
                  <img
                    src={mediaItems[currentMediaIndex].image_url}
                    alt={mediaItems[currentMediaIndex].title}
                    onLoad={() =>
                      handleMediaLoad(mediaItems[currentMediaIndex].id)
                    }
                    style={{
                      transition: 'opacity 0.3s ease-in-out',
                      opacity: loadedMedia[mediaItems[currentMediaIndex].id]
                        ? '1'
                        : '0',
                      display: loadedMedia[mediaItems[currentMediaIndex].id]
                        ? 'block'
                        : 'none',
                      maxWidth: '70%',
                      maxHeight: '70%',
                    }}
                    loading="eager"
                  />
                </>
              ) : (
                <video
                  src={mediaItems[currentMediaIndex].video_url}
                  alt={mediaItems[currentMediaIndex].title}
                  style={{
                    maxWidth: '75%',
                    maxHeight: '60%',
                  }}
                  controls
                  preload="auto"
                />
              )}
              {mediaItems.length > 1 && (
                <ButtonContainer sx={{ justifyContent: 'center' }}>
                  <IconButton
                    sx={{
                      color: '#21252b',
                      fontFamily: 'DM Serif Display',
                      fontSize: '20px',
                    }}
                    onClick={handlePrevMedia}
                    disableRipple
                  >
                    Prev /
                  </IconButton>
                  <IconButton
                    sx={{
                      color: '#21252b',
                      fontFamily: 'DM Serif Display',
                      fontSize: '20px',
                    }}
                    onClick={handleNextMedia}
                    disableRipple
                  >
                    Next
                  </IconButton>
                </ButtonContainer>
              )}
            </ModalContent>
          </Fade>
        </Modal>
      )}
    </div>
  );
};

export default ProjectDetails;
