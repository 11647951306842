import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Typography,
  Container,
  Button,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Stack,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import logo from '../../assets/images/logo2.png';
import { connect } from 'react-redux';

const categoriesList = [
  { name: 'About', path: '/about', order: 100, id: 0 },
  { name: 'Contact', path: '/contact', order: 101, id: 0 },
];

const logoStyle = {
  width: '100px',
  height: 'auto',
};

const NavBar = ({ categories }) => {
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);
  const location = useLocation();

  if (categories != null && categories.length > 0) {
    categories.forEach((cat) => {
      const alreadyAdded = categoriesList.some(
        (existingCat) => existingCat.id === cat.id
      );
      if (!alreadyAdded)
        categoriesList.push({
          name: cat.title,
          path: `/home/${cat.id}`,
          order: cat.order,
          id: cat.id,
        });
    });
  }

  const sortedCategories = categoriesList.sort((a, b) => {
    if (a.order === 0) return 1;
    if (b.order === 0) return -1;
    return a.order - b.order;
  });

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setIsDrawerOpen(open);
  };

  return (
    <AppBar
      position="sticky"
      sx={{
        backgroundColor: '#ffffff',
        boxShadow: 'none',
      }}
    >
      <Container maxWidth="xl" sx={{ padding: '0px!important' }}>
        <Toolbar disableGutters>
          {/* Menu Icon */}
          <Box display={{ xs: 'block', md: 'none' }}>
            <IconButton
              size="large"
              aria-label="menu"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={toggleDrawer(true)}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
          </Box>
          {/* Logo and Typography (Mobile View) */}
          <Box
            display={{ xs: 'flex', md: 'none' }}
            flexGrow={1}
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <Link to="/home/3">
              <img
                src={logo}
                height="auto"
                width="100px"
                alt="logo"
                style={{ marginTop: '10px' }}
              />
            </Link>
            <Typography
              noWrap
              variant="caption"
              sx={{
                color: 'secondary.main',
                mb: 2,
                textDecoration: 'none',
                letterSpacing: '.1rem',
              }}
            >
              FASHION STYLIST | CREATIVE DIRECTOR
            </Typography>
          </Box>
          {/* Logo and Navigation Categories (Desktop View) */}
          <Box flexGrow={1}>
            <Box
              display={{ xs: 'none', md: 'flex' }}
              flexDirection="column"
              alignItems="center"
            >
              <Link to="/home/3">
                <img src={logo} style={logoStyle} alt="logo" />
              </Link>
              <Typography
                noWrap
                variant="caption"
                sx={{
                  display: 'flex',
                  textDecoration: 'none',
                  color: 'secondary.main',
                  letterSpacing: '.3rem',
                }}
              >
                FASHION STYLIST | CREATIVE DIRECTOR
              </Typography>
            </Box>
            {/* Navigation Categories */}
            <Box
              display={{ xs: 'none', md: 'flex' }}
              justifyContent="center"
              marginTop="30px"
              marginBottom="30px"
            >
              {sortedCategories.map((category) => (
                <Button
                  key={category.name}
                  component={Link}
                  to={category.path}
                  disableRipple
                  sx={{
                    display: 'block',
                    letterSpacing: '.06em',
                    pl: 0,
                    pr: 2.5,
                    py: 0,
                    color:
                      location.pathname === category.path
                        ? 'inherit'
                        : 'secondary.main',
                    '&:hover': {
                      color: '#21252b',
                      backgroundColor: 'white',
                    },
                  }}
                >
                  {category.name}
                </Button>
              ))}
            </Box>
          </Box>
        </Toolbar>
      </Container>
      {/* Drawer for smaller screens */}
      <Drawer anchor="right" open={isDrawerOpen} onClose={toggleDrawer(false)}>
        <List sx={{ paddingLeft: '15px', paddingTop: '20px' }}>
          {sortedCategories.map((category) => (
            <ListItem
              button
              key={category.name}
              component={Link}
              to={category.path}
              onClick={() => {
                toggleDrawer(false)();
              }}
              sx={{
                letterSpacing: '.06em',
                color:
                  location.pathname === category.path
                    ? 'inherit'
                    : 'secondary.main',
                '&:hover': {
                  color: '#21252b',
                  backgroundColor: 'white',
                },
              }}
            >
              <ListItemText primary={category.name} />
            </ListItem>
          ))}
          <Stack
            direction="row"
            justifyContent="center"
            useFlexGap
            sx={{
              color: 'text.secondary',
            }}
          >
            <IconButton
              href="https://www.instagram.com/haifaakhalidstylist?igsh=MWU3ajduMjYxa3dsMg%3D%3D"
              aria-label="Instagram"
              sx={{ color: 'text.primary' }}
              target="_blank"
            >
              <InstagramIcon />
            </IconButton>
            <IconButton
              href="https://www.linkedin.com/in/haifaa-khaled-2468a11b9?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app"
              aria-label="LinkedIn"
              sx={{ color: 'text.primary' }}
              target="_blank"
            >
              <LinkedInIcon />
            </IconButton>
          </Stack>
        </List>
      </Drawer>
    </AppBar>
  );
};

const mapStateToProps = (state) => ({
  categories: state.categoryState.categories,
});

export default connect(mapStateToProps)(NavBar);
