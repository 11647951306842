import React, { useEffect, useRef } from "react";
import rush from "../../assets/images/rush.jpg";
import barlant from "../../assets/images/barlant.jpg";
import zaid from "../../assets/images/zaid.jpg";
import amy from "../../assets/images/amy.jpg";
import samsung from "../../assets/images/samsung.jpg";
import { Typography } from "@mui/material";

const HorizontalScroll = () => {
  const logoRef = useRef(null);

  useEffect(() => {
    const logos = logoRef.current;
    const clone = logos.cloneNode(true); // Clone the logo list
    clone.setAttribute("aria-hidden", "true"); // Mark clone as hidden for accessibility
    logos.parentNode.appendChild(clone); // Append the cloned list
  }, []);

  return (
    <div className="clients-section">
      <div className="clients-title">
        <Typography
          sx={{
            fontFamily: "DM Serif Display, sans-serif, Tajawal",
            fontSize: "25px",
            py: 6,
          }}
        >
          CLIENTS
        </Typography>
      </div>
      <div className="scroll-container">
        <ul className="logo-list" ref={logoRef}>
          <li>
            <img src={rush} alt="Rush" />
          </li>
          <li>
            <img src={barlant} alt="Barlant" />
          </li>
          <li>
            <img src={zaid} alt="Zaid" />
          </li>
          <li>
            <img src={amy} alt="Amy" />
          </li>
          <li>
            <img src={samsung} alt="Samsung" />
          </li>
        </ul>
      </div>
    </div>
  );
};

export default HorizontalScroll;
