import { createTheme } from '@mui/material/styles';

export const themeOptions = {
  palette: {
    mode: 'light',
    primary: {
      main: '#45515f',
      contrastText: '#21252b',
    },
    secondary: {
      main: '#6a7b8d',
      contrastText: '#21252b',
    },
    background: {
      default: '#ffffff',
      paper: '#ffffff',
    },
    text: {
      primary: 'rgb(33, 37, 43)',
      secondary: '#45515f',
      disabled: '#6a7b8d',
    },
    divider: 'rgba(237, 238, 241, 1)',
  },
  typography: {
    fontFamily: 'DM Serif Display',
  },
};

export const theme = createTheme(themeOptions);
