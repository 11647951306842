import * as React from 'react';
import { Typography, Grid, IconButton, Box } from '@mui/material';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import imagecontact from '../../assets/images/DSC_3433.jpg';
import logo from '../../assets/images/logo2.png';

const logoStyle = {
  width: '100px',
  height: 'auto',
};

const Contact = () => {
  return (
    <Grid
      container
      spacing={2}
      alignItems="center"
      mt={3}
      justifyContent="space-evenly"
    >
      <Grid item xs={12} md={4}>
        <img
          src={imagecontact}
          alt="contact"
          loading="lazy"
          style={{ width: '100%', height: '100%' }}
        />
      </Grid>
      <Grid item xs={12} md={5} display="grid" justifyItems="center">
        <img src={logo} style={logoStyle} alt="logo" />
        <Typography
          noWrap
          variant="body"
          sx={{
            mr: 2,
            display: 'flex',
            textDecoration: 'none',
            color: 'secondary.main',
            letterSpacing: '.1rem',
            fontFamily: 'Montserrat, sans-serif',
            pb: 2,
            pt: 2,
          }}
          component="a"
          href="mailto:Haifaakhalidofficial@yahoo.com"
        >
          Haifaakhalidofficial@yahoo.com
        </Typography>
        <Box>
          <IconButton
            href="https://www.instagram.com/haifaakhalidstylist?igsh=MWU3ajduMjYxa3dsMg%3D%3D"
            aria-label="Instagram"
            sx={{ color: 'text.primary' }}
            target="_blank"
          >
            <InstagramIcon />
          </IconButton>
          <IconButton
            href="https://www.linkedin.com/in/haifaa-khaled-2468a11b9?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app"
            aria-label="LinkedIn"
            sx={{ color: 'text.primary' }}
            target="_blank"
          >
            <LinkedInIcon />
          </IconButton>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Contact;
