import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import logo from '../../assets/images/logo2.png';

const logoStyle = {
  width: '100px',
  height: 'auto',
  marginTop: '20px',
};

const Footer = () => {
  return (
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 4, sm: 8 },
        pt: { xs: 8, sm: 10 },
        textAlign: { sm: 'center', md: 'left' },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          justifyContent: 'space-between',
          width: '100%',
          borderTop: '1px solid',
          borderColor: 'divider',
          alignItems: 'center',
        }}
      >
        <Box sx={{ order: { xs: 2, sm: 1 }, mb: { xs: 2, sm: 0 } }}>
          <Stack
            direction="row"
            justifyContent="left"
            spacing={1}
            useFlexGap
            sx={{
              color: 'text.secondary',
            }}
          >
            <IconButton
              href="https://www.instagram.com/haifaakhalidstylist?igsh=MWU3ajduMjYxa3dsMg%3D%3D"
              aria-label="Instagram"
              sx={{ color: 'text.primary' }}
              target="_blank"
            >
              <InstagramIcon />
            </IconButton>
            <IconButton
              href="https://www.linkedin.com/in/haifaa-khaled-2468a11b9?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app"
              aria-label="LinkedIn"
              sx={{ color: 'text.primary' }}
              target="_blank"
            >
              <LinkedInIcon />
            </IconButton>
          </Stack>
        </Box>
        <Box
          sx={{
            order: { xs: 1, sm: 2 },
            // ml: { sm: '-15px' },
          }}
        >
          <img src={logo} style={logoStyle} alt="logo" />
        </Box>
        <Box sx={{ order: 3 }}>
          <Typography variant="body2">
            © Haifa'a Khalid {new Date().getFullYear()}
          </Typography>
        </Box>
      </Box>
    </Container>
  );
};

export default Footer;
