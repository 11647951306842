import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { theme } from '../components/theme';
import { Fab, CssBaseline, Toolbar } from '@mui/material';
import {
  NavBar,
  About,
  Contact,
  ScrollTop,
  Footer,
  ProjectList,
  ProjectDetails,
} from '.';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import '../styles/theme.css';

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <NavBar />
      <Toolbar id="back-to-top-anchor" sx={{ minHeight: '0px !important' }} />
      <main>
        <Routes>
          <Route path="/" element={<Navigate to="/home/3" />} />
          <Route
            path="/home/:catID"
            element={<ProjectList  />}
          />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/project/:projectID" element={<ProjectDetails />} />
        </Routes>
        <ScrollTop>
          <Fab
            size="medium"
            aria-label="scroll back to top"
            sx={{ backgroundColor: '#edeef1', color: '#21252b' }}
          >
            <KeyboardArrowUpIcon />
          </Fab>
        </ScrollTop>
        <Footer />
      </main>
    </ThemeProvider>
  );
};

export default App;
