import { GET_CATEGORIES } from './actionTypes';
import { instance } from './instance';

export const getCategories = () => {
  return async (dispatch) => {
    try {
      const response = await instance.get('categories');
      dispatch({
        type: GET_CATEGORIES,
        payload: response.data,
      });
    } catch (err) {
      console.log(err);
    }
  };
};
