import { GET_ABOUT } from '../actions/actionTypes';

const initialState = {
  about: [],
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_ABOUT:
      const about = payload;
      return {
        ...state,
        about: about,
      };
    default:
      return state;
  }
};

export default reducer;
