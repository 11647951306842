import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import {
  ImageList,
  ImageListItem,
  Typography,
  Box,
  styled,
  Skeleton,
} from '@mui/material';
import { PlayCircleFilled } from '@mui/icons-material';
import { connect } from 'react-redux';

const Overlay = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(33, 37, 43, 0.5)',
  opacity: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: '#fff',
  transition: 'opacity 0.3s ease',
  '&:hover': {
    opacity: 1,
  },
  '& > *': {
    textAlign: 'center',
    width: '100%',
  },
}));

const ImageContainer = styled(Box)(({ theme }) => ({
  overflow: 'hidden',
  position: 'relative',
  width: '100%',
  height: 340, // Adjust height based on your ImageList rowHeight
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  '& img': {
    width: 'auto',
    maxWidth: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  '& img.large-size': {
    maxWidth: '530px',
    maxHeight: '340px',
    width: '100%',
    height: '400px',
    objectFit: 'cover',
  },
}));

const PlayButton = styled(PlayCircleFilled)({
  fontSize: 64,
  color: 'rgba(255, 255, 255, 0.9)',
  cursor: 'pointer',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
});

const ProjectList = ({ projects }) => {
  const { catID } = useParams();
  const [loadedImages, setLoadedImages] = useState({});

  useEffect(() => {
    // Initially, mark all images as not loaded
    const initialImageLoadState = projects.reduce((acc, project) => {
      acc[project.id] = false;
      return acc;
    }, {});
    setLoadedImages(initialImageLoadState);
  }, [projects]);

  const handleImageLoad = (projectId) => {
    setLoadedImages((prevLoadedImages) => ({
      ...prevLoadedImages,
      [projectId]: true, // Mark this image as loaded
    }));
  };

  const filteredProjects = projects.filter(
    (project) => project.category.toString() === catID
  );

  filteredProjects.sort((a, b) => a.order - b.order);

  return (
    <ImageList
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        gap: '15px!important',
        justifyContent: 'space-evenly',
      }}
      cols={4}
      rowHeight={350}
    >
      {filteredProjects.map((project) => (
        <Link key={project.id} to={`/project/${project.id}`}>
          <ImageListItem>
            <ImageContainer>
              <img
                src={project.thumbnail_url}
                alt={project.title}
                className={project.size ? project.size : ''}
                style={{
                  display: loadedImages[project.id] ? 'block' : 'none',
                }}
                onLoad={() => handleImageLoad(project.id)}
              />
              {!loadedImages[project.id] && (
                <Skeleton variant="rectangular" width={300} height={350} />
              )}
              {project.images_count === 0 && project.videos_count > 0 ? (
                <PlayButton />
              ) : (
                <></>
              )}
              <Overlay>
                <Typography
                  variant="body1"
                  sx={{
                    fontFamily: 'Montserrat, sans-serif',
                    fontWeight: 700,
                    textTransform: 'uppercase',
                  }}
                >
                  {project.title}
                </Typography>
              </Overlay>
            </ImageContainer>
          </ImageListItem>
        </Link>
      ))}
    </ImageList>
  );
};

const mapStateToProps = (state) => ({
  projects: state.projectState.projects,
});

export default connect(mapStateToProps)(ProjectList);
