import { combineReducers } from 'redux';

import projectReducer from './project';
import categoryReducer from './category';
import aboutReducer from './about';

export default combineReducers({
  categoryState: categoryReducer,
  projectState: projectReducer,
  aboutState: aboutReducer,
});
