import * as React from "react";
import { Typography, Grid, Paper } from "@mui/material";
import Carousel from "react-material-ui-carousel";
import image1 from "../../assets/images/DSC_6676.jpg";
import image2 from "../../assets/images/DSC_6784.jpg";
import image3 from "../../assets/images/DSC_6502.jpg";
import HorizontalScroll from "../HorizontalScroll/HorizontalScroll";

const About = () => {
  const carouselItems = [
    {
      image: image1,
      caption: "First Image",
    },
    {
      image: image2,
      caption: "Second Image",
    },
    {
      image: image3,
      caption: "Third Image",
    },
  ];

  return (
    <Grid
      container
      spacing={2}
      alignItems="center"
      mt={3}
      justifyContent="space-evenly"
    >
      <Grid item xs={12} md={4}>
        <Carousel
          height="500px"
          autoPlay={true}
          indicators={false}
          navButtonsAlwaysInvisible
          fullHeightHover={false}
          animation="fade"
        >
          {carouselItems.map((item, index) => (
            <Paper
              key={index}
              sx={{
                overflow: "hidden",
                boxShadow: "none",
                borderRadius: "0px",
              }}
            >
              <img
                src={item.image}
                alt={item.caption}
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            </Paper>
          ))}
        </Carousel>
      </Grid>
      <Grid item xs={12} md={5}>
        <Typography
          variant="body1"
          sx={{
            wordSpacing: "0.1em",
            letterSpacing: "0.02em",
            whiteSpace: "break-spaces",
          }}
        >
          With a fashion career that spans over six years, Jordan based stylist
          Haifaa Khalid has an extensive folio of work.
          <br />
          <br />
          From Fashion Design, where her career began, to working as a Creative
          Director, Editorial Stylist and Personal/Celebrity Stylist, Haifaa is
          continually seeking opportunities to grow and expand her repertoire.
          <br />
          <br />
          With formal qualifications an Editorial Styling Diploma from the
          London College Of Style and Master's Degree in Fashion Styling
          specialized in (Advanced Editorial, Personal Styling, and Professional
          Styling) from the Australian Style Institute, when working with Haifaa
          you'll not only get the experience of a trained professional stylist,
          but you'll see the passion and dedication she has to her craft and the
          impact this has on any project she is a part of.
        </Typography>
      </Grid>
      <Grid item xs={12} md={10} marginTop={3}>
        <HorizontalScroll />
      </Grid>
    </Grid>
  );
};

export default About;
